import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { List } from 'immutable';
import ReactMarkdown from 'react-markdown';

import {
  answerQuestionOption,
  offBeforeUnload,
  createActivityProductVersionSessionEvent,
  updateActivityProductVersionSession,
  sendHeartBeat,
} from '^/actions/actions';
import {
  markMAPCompleteAndFinish,
  setMAPProductVersionQuestionIndexWithEvent,
} from '^/actions/actionSequences';
import PureComponent from '^/components/PureComponent';
import MAPNav from './MAPNav';
import AssessmentContainer from './AssessmentContainer';
import AssessmentBody from './AssessmentBody';
import AssessmentAside from './AssessmentAside';
import MAPBodyAndBottomNav, { MAP_PAGE } from './MAPBodyAndBottomNav';
import { getMAPTextByKey, getQuestionIdFromIndex } from '^/productVersions';
import MAPStartConfirmation from './MAPStartConfirmation';
import InstructionsToggleButton from './InstructionsToggleButton';
import AssessmentHeaderDetails from './AssessmentHeaderDetails';

const DEFAULT_SESSION_LENGTH_MINS = 30;
const HEARTBEAT_FREQUENCY = 15000;

export class MAPProductVersion extends PureComponent {
  UNSAFE_componentWillMount() {
    this.preloadImages();
    const { activity, productVersion } = this.props;
    this.props.createActivityProductVersionSessionEvent(
      activity.get('id'),
      productVersion.get('id'),
      getQuestionIdFromIndex(
        this.props.imageMatchQuestionsWithAnswers,
        this.props.questionIdx
      )
    );
    this.startHeartbeats();
  }

  startHeartbeats() {
    this.sendHeartbeat();
    this.heartbeatInterval = setInterval(
      () => this.sendHeartbeat(),
      HEARTBEAT_FREQUENCY
    );
  }

  sendHeartbeat() {
    const { activity, productVersion } = this.props;
    this.props.sendHeartBeat(productVersion.get('id'), activity.get('id'));
  }

  stopHeartbeats() {
    clearInterval(this.heartbeatInterval);
  }

  componentWillUnmount() {
    this.stopHeartbeats();
  }

  preloadImages() {
    const questionsWithAnswers = this.props.imageMatchQuestionsWithAnswers;
    questionsWithAnswers.map(qAndA => {
      const question = qAndA.get(0);
      const { image, options } = question.toObject();
      const mainImage = new Image();
      mainImage.src = '/static' + image;

      options.map(questionOption => {
        const optionImageSrc = questionOption.get('image');
        const optionImage = new Image();
        optionImage.src = '/static' + optionImageSrc;
      });
    });
  }

  navigateToQuestion(questionId) {
    const newQuestionIdx = this.props.imageMatchQuestionsWithAnswers.findIndex(
      qAndA => qAndA.getIn([0, 'id']) === questionId
    );
    if (newQuestionIdx !== undefined && newQuestionIdx !== null) {
      const { activity, productVersion } = this.props;
      this.props.setMAPProductVersionQuestionIndexWithEvent(
        activity.get('id'),
        productVersion.get('id'),
        questionId,
        newQuestionIdx
      );
    }
  }

  timeOutMap() {
    const { activity, productVersion, questionCollectionIdx } = this.props;
    this.props.markMAPCompleteAndFinish(
      activity,
      productVersion,
      questionCollectionIdx,
      this.props.uiLanguage,
      getMAPTextByKey(productVersion, 'timeout_text')
    );
  }

  render() {
    const {
      questionIdx,
      mapProductVersionState,
      productVersion,
      activity,
      questionCollectionIdx,
      imageMatchQuestionsWithAnswers,
    } = this.props;
    const { timedOut, timerRunning, page } = mapProductVersionState.toObject();

    if (timedOut) {
      this.timeOutMap();
    }

    const session = productVersion
      .get('activity_product_version_sessions', List())
      .find(each => each.get('activity') === activity.get('id'));

    const minutesRemaining =
      session &&
      session.getIn([
        'map_activity_product_version_session',
        'minutes_remaining',
      ]);
    const timerMinutes =
      minutesRemaining !== null && minutesRemaining !== undefined
        ? minutesRemaining
        : productVersion.getIn(
            ['map_product_version', 'max_session_length_minutes'],
            DEFAULT_SESSION_LENGTH_MINS
          );

    const questionWithAnswer = imageMatchQuestionsWithAnswers.get(questionIdx);
    const instructions = questionWithAnswer.getIn([0, 'text']);

    if (page === MAP_PAGE.START_CONFIRMATION) {
      return (
        <MAPStartConfirmation
          questionsWithAnswers={imageMatchQuestionsWithAnswers}
          activity={activity}
          productVersion={productVersion}
        />
      );
    }

    return (
      <AssessmentContainer hasAsides>
        <AssessmentAside>
          <MAPNav
            questionsWithAnswers={imageMatchQuestionsWithAnswers}
            navigateToQuestion={questionId =>
              this.navigateToQuestion(questionId)
            }
            currentQuestionId={imageMatchQuestionsWithAnswers.getIn([
              questionIdx,
              0,
              'id',
            ])}
            showTimer={timerRunning}
            timerMinutes={timerMinutes}
            onCountdownComplete={() => this.timeOutMap()}
          />
        </AssessmentAside>

        <AssessmentBody
          questionIndex={questionIdx}
          className="assessment-small-text"
        >
          <MAPBodyAndBottomNav
            page={page}
            questionCollectionIdx={questionCollectionIdx}
            questionsWithAnswers={imageMatchQuestionsWithAnswers}
            timerRunning={timerRunning}
            activity={activity}
            productVersion={productVersion}
            initialRemainingMinutes={timerMinutes}
          />
        </AssessmentBody>

        <AssessmentAside className="assessment-small-text">
          <AssessmentHeaderDetails
            productVersion={productVersion}
            questionCollectionIdx={questionCollectionIdx}
            questionCollection={undefined}
            raterFor={undefined}
            hideSectionName={activity.get('is_staged')}
          />
          {instructions && (
            <InstructionsToggleButton questionIndex={questionIdx}>
              <ReactMarkdown source={instructions} />
            </InstructionsToggleButton>
          )}
        </AssessmentAside>
      </AssessmentContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    responses: state.multiResponses.get('answerQuestion'),
    questionIdx: state.ui.get('mapProductVersionQuestionIndex'),
    mapProductVersionState: state.mapProductVersionState,
    uiLanguage: state.ui.get('uiLanguage'),
  };
}

export default connect(mapStateToProps, {
  answerQuestionOption,
  setMAPProductVersionQuestionIndexWithEvent,
  markMAPCompleteAndFinish,
  offBeforeUnload,
  createActivityProductVersionSessionEvent,
  updateActivityProductVersionSession,
  sendHeartBeat,
})(MAPProductVersion);
