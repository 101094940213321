import classNames from 'classnames';
import i18next from 'i18next';
import { Map } from 'immutable';
import React from 'react';

import {
  ACTIVITY_TYPES,
  PRODUCT_TYPES,
} from '^/components/productVersions/choices';

interface OwnProps {
  productVersion: Map<string, any>;
  raterFor: Map<string, any> | undefined;
  questionCollection: Map<string, any> | undefined;
  questionCollectionIdx: number | undefined;
  showCompletionTime: boolean | undefined;
  useProductVersionCompletionTime: boolean | undefined;
  hideSectionName: boolean | undefined;
  blankCompletionTime?: boolean;
  organisationName?: string;
}

type Props = OwnProps;

const getTitle = (
  productVersion: Map<string, any>,
  raterFor: Map<string, any> | undefined,
  organisationName?: string
) => {
  if (
    productVersion.getIn(['product', 'activity_type']) ===
    ACTIVITY_TYPES.CHOICES.THREE_SIXTY
  ) {
    if (raterFor) {
      return i18next.t<string>('360 Feedback for {{raterName}}', {
        raterName: raterFor.get('full_name'),
      });
    }
    return i18next.t<string>('Your 360 Feedback');
  } else if (
    productVersion.getIn(['product', 'type']) === PRODUCT_TYPES.CHOICES.SJT
  ) {
    return i18next.t<string>('Your {{orgName}} {{productName}}', {
      orgName: organisationName,
      productName: productVersion.getIn(['product', 'name']),
    });
  }
  return i18next.t<string>('Your {{productName}} Assessment', {
    productName: productVersion.getIn(['product', 'name']),
  });
};

const getQuestionCollection = ({
  questionCollection,
  questionCollectionIdx,
  productVersion,
}: Props) => {
  if (questionCollectionIdx) {
    return productVersion.getIn([
      'questioncollection_set',
      questionCollectionIdx,
    ]);
  } else if (questionCollection) {
    return questionCollection;
  }
  return productVersion.getIn(['questioncollection_set', 0]);
};

const AssessmentHeaderDetails = (props: Props) => {
  const {
    productVersion,
    raterFor,
    showCompletionTime,
    hideSectionName,
    useProductVersionCompletionTime,
    questionCollectionIdx,
    blankCompletionTime,
    organisationName,
  } = props;

  const icon = productVersion.getIn(['product', 'icon'], null);
  const isSJT =
    productVersion.getIn(['product', 'type']) === PRODUCT_TYPES.CHOICES.SJT;
  const questioncollectionSet = productVersion.get('questioncollection_set');
  const currentQuestionCollection = getQuestionCollection(props);
  const completionTimeText = useProductVersionCompletionTime
    ? productVersion.get('completion_time_text')
    : currentQuestionCollection.get('completion_time_text') ||
      (!questionCollectionIdx && productVersion.get('completion_time_text'));
  const hasMultipleQuestionCollections =
    questioncollectionSet && questioncollectionSet.count() > 1;
  const questionCollectionName = currentQuestionCollection.get('name');

  return (
    <div>
      <div
        className={classNames('asset-name-and-logo', 'inline', {
          'align-middle': isSJT,
        })}
      >
        {icon && <img className="asset-logo" src={icon} />}
        <div className="asset-name">
          {getTitle(productVersion, raterFor, organisationName)}
        </div>
      </div>

      {!hideSectionName && hasMultipleQuestionCollections && (
        <div className="asset-section">{questionCollectionName}</div>
      )}

      {showCompletionTime && blankCompletionTime && (
        <div className="asset-time">&nbsp;</div>
      )}

      {showCompletionTime && completionTimeText && !blankCompletionTime && (
        <div className="asset-time">{completionTimeText}</div>
      )}
    </div>
  );
};

export default AssessmentHeaderDetails;
