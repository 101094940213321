import { makeChoices } from '^/utils-ts';

export const PRODUCT_TYPES = makeChoices([
  ['THREE_SIXTY', '360'],
  ['EAP', 'EAP'],
  ['ISP', 'ISP'],
  ['POSITIVE_RESILIENCE_PROFILER', 'Positive Resilience Profiler'],
  ['MAP', 'MAP'],
  ['PSYCAP_POTENTIAL', 'PsyCap Potential'],
  ['DISC', 'DISC'],
  ['JOB_PROFILER', 'Disc Discovery Job Profiler'],
  ['SJT', 'SJT'],
]);

export const REPORT_TEMPLATES = makeChoices([
  ['TEST', 'Test Report'],
  ['ISP', 'Influencing Styles Profiler'],
  ['MAP', 'Mental Agility Profiler'],
  ['EAP', 'Emotional Agility Profiler'],
  ['THREE_SIXTY', '360'],
  ['POSITIVE_RESILIENCE_PROFILER', 'Positive Resilience Profiler'],
  ['EXECUTIVE_SUMMARY', 'Executive Summary'],
  ['INTERVIEW_INSIGHTS', 'Interview Insights'],
  ['PROFILER', 'Profiler Report'],
  ['INDIVIDUAL_DEVELOPMENT', 'Individual Development'],
  ['DISC_INDIVIDUAL', 'DISC Individual'],
]);

export const ACTIVITY_TYPES = makeChoices([
  ['THREE_SIXTY', 'Three Sixty'],
  ['PSYCHOMETRIC', 'Psychometric'],
  ['JOB_PROFILER', 'Job Profiler'],
]);
